import React from "react";
import styled from "styled-components";

export const buttonStyle = {
  fontSize: 14,
  fontWeight: 400,
  borderRadius: 20,
  border: "solid",
  color: "white",
  backgroundColor: "lightgray",
  margin: "5px",
};

export const Anchor = styled.a`
  color: white;
`;
