import React from "react";
import CookieConsent from "react-cookie-consent";
import { Anchor, buttonStyle } from "./styles";
import Text from "../../atoms/Text";
import { cookiesFileURL } from "../../../data/constants";

const CookiesManager = () => {
  function onDeclineCookies() {
    // @ts-ignore
    window[`ga-disable-${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`] = false;
    // @ts-ignore
    window[`gatsby-gdpr-google-tagmanager`] = false;
  }
  function onAcceptCookies() {
    // @ts-ignore
    window[`ga-disable-${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`] = true;
    // @ts-ignore
    window[`gatsby-gdpr-google-tagmanager`] = true;
  }
  return (
    <CookieConsent
      enableDeclineButton
      declineButtonStyle={{
        ...buttonStyle,
        backgroundColor: "rgba(200, 200, 200, 0.5)",
      }}
      buttonStyle={{
        ...buttonStyle,
        backgroundColor: "rgba(0, 255, 100, 0.5)",
      }}
      onAccept={onAcceptCookies}
      onDecline={onDeclineCookies}
      location="bottom"
      buttonText={"Accepter"}
      declineButtonText={"Refuser"}
      cookieName="gatsby-gdpr-google-tagmanager"
    >
      <Text>
        Nous utilisons des cookies afin d'analyser les données de nos visiteurs,
        améliorer notre site Web, afficher un contenu personnalisé et vous faire
        vivre une expérience inoubliable.
      </Text>
      <Anchor href={cookiesFileURL}>
        Consulter notre politique de confidentialité
      </Anchor>
    </CookieConsent>
  );
};

export default CookiesManager;
