import React from "react";
import styled from "styled-components";

export interface TextProps {
  children: string;
  className?: any;
}

const Component = styled.p`
  margin: 0;
  font-family: Raleway, sans-serif;
`;

const Text = ({ children, className }: TextProps) => (
  <Component className={className}>{children}</Component>
);

export default Text;
